var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xs-12 col-sm-12 col-md-12 col-lg-12"},[_c('a-card',{attrs:{"size":"small"}},[_c('template',{slot:"title"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xs-6 col-sm-6 col-md-6 col-lg-6"},[_c('h5',[_vm._v("Cek Posisi Keuangan")]),_c('a-checkbox',{on:{"change":_vm.onChange},model:{value:(_vm.tidaksama),callback:function ($$v) {_vm.tidaksama=$$v},expression:"tidaksama"}},[_vm._v(" Hanya tampilkan yang tidak sama ")])],1),_c('div',{staticClass:"col-xs-6 col-sm-6 col-md-6 col-lg-6"},[_c('a-select',{staticClass:"float-right",staticStyle:{"width":"100px"},attrs:{"show-search":"","placeholder":"Pilih Kode Cabang","option-filter-prop":"children","filter-option":_vm.filterOption},on:{"change":_vm.onSearch},model:{value:(_vm.filterKodeKantor),callback:function ($$v) {_vm.filterKodeKantor=$$v},expression:"filterKodeKantor"}},[_c('a-select-option',{attrs:{"value":"all"}},[_vm._v(" All ")]),_vm._l((_vm.dataCabangList),function(data,index){return _c('a-select-option',{key:index,attrs:{"value":data.kode}},[_vm._v(" "+_vm._s(data.kode)+" ")])})],2)],1)])]),_c('div',{staticClass:"row",staticStyle:{"padding-left":"10px","padding-right":"10px"}},[_c('div',{staticClass:"col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-5"},[_c('h5',[_vm._v("Laporan Keuangan vs Detail")]),_c('a-table',{staticStyle:{"margin-left":"-13px","margin-right":"-13px"},attrs:{"bordered":false,"columns":_vm.columns,"data-source":_vm.rowDataRekap,"size":"small","loading":_vm.loading,"pagination":{
              hideOnSinglePage: true,
              defaultPageSize: 100,
              showQuickJumper: true,
              showSizeChanger: true,
              showTotal: (total) => `Total ${total} items`,
              pageSizeOptions: ['100', '200', '300'],
            }},scopedSlots:_vm._u([{key:"filterDropdown",fn:function({
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
                column,
              }){return _c('div',{staticStyle:{"padding":"8px"}},[_c('a-input',{directives:[{name:"ant-ref",rawName:"v-ant-ref",value:((c) => (_vm.searchInput = c)),expression:"(c) => (searchInput = c)"}],staticStyle:{"width":"188px","margin-bottom":"8px","display":"block"},attrs:{"placeholder":`Search ${column.dataIndex}`,"value":selectedKeys[0]},on:{"change":(e) =>
                    setSelectedKeys(e.target.value ? [e.target.value] : []),"pressEnter":() => _vm.handleSearch(selectedKeys, confirm, column.dataIndex)}}),_c('a-button',{staticStyle:{"width":"90px","margin-right":"8px"},attrs:{"type":"primary","icon":"search","size":"small"},on:{"click":() => _vm.handleSearch(selectedKeys, confirm, column.dataIndex)}},[_vm._v(" Search ")]),_c('a-button',{staticStyle:{"width":"90px"},attrs:{"size":"small"},on:{"click":() => _vm.handleReset(clearFilters)}},[_vm._v(" Reset ")])],1)}},{key:"filterIcon",fn:function(filtered){return _c('a-icon',{style:({ color: filtered ? '#108ee9' : undefined }),attrs:{"type":"search"}})}},{key:"customRender",fn:function(text, record, index, column){return [(_vm.searchText && _vm.searchedColumn === column.dataIndex)?_c('span',[_vm._l((text
                    .toString()
                    .split(
                      new RegExp(`(?<=${_vm.searchText})|(?=${_vm.searchText})`, 'i')
                    )),function(fragment,i){return [(fragment.toLowerCase() === _vm.searchText.toLowerCase())?_c('mark',{key:i,staticClass:"highlight"},[_vm._v(_vm._s(fragment))]):[_vm._v(_vm._s(fragment))]]})],2):[_c('span',[_vm._v(" "+_vm._s(text))])]]}},{key:"keterangan",fn:function(text){return _c('span',{domProps:{"innerHTML":_vm._s(_vm.getHtml(text))}})}},{key:"keterangan2",fn:function(text){return _c('span',{domProps:{"innerHTML":_vm._s(_vm.getHtml(text))}})}},{key:"jumlah",fn:function(text){return _c('span',{},[_vm._v(" "+_vm._s(_vm.formatNumber(text))+" ")])}},{key:"jumlah2",fn:function(text){return _c('span',{},[_vm._v(" "+_vm._s(_vm.formatNumber(text))+" ")])}},{key:"selisih",fn:function(text){return _c('span',{},[_vm._v(" "+_vm._s(_vm.formatNumber(text))+" ")])}},{key:"status",fn:function(text){return _c('a-tag',{attrs:{"color":text === 'Cocok' ? 'green' : 'red'}},[_vm._v(" "+_vm._s(text)+" ")])}}])})],1),_c('div',{staticClass:"col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-5"},[_c('h5',[_vm._v("Cek Posisi Keuangan")]),_c('a-table',{staticStyle:{"margin-left":"-13px","margin-right":"-13px"},attrs:{"bordered":false,"columns":_vm.columns,"data-source":_vm.rowDataKeuangan,"size":"small","loading":_vm.loading,"pagination":{
              hideOnSinglePage: true,
              defaultPageSize: 100,
              showQuickJumper: true,
              showSizeChanger: true,
              showTotal: (total) => `Total ${total} items`,
              pageSizeOptions: ['100', '200', '300'],
            }},scopedSlots:_vm._u([{key:"filterDropdown",fn:function({
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
                column,
              }){return _c('div',{staticStyle:{"padding":"8px"}},[_c('a-input',{directives:[{name:"ant-ref",rawName:"v-ant-ref",value:((c) => (_vm.searchInput = c)),expression:"(c) => (searchInput = c)"}],staticStyle:{"width":"188px","margin-bottom":"8px","display":"block"},attrs:{"placeholder":`Search ${column.dataIndex}`,"value":selectedKeys[0]},on:{"change":(e) =>
                    setSelectedKeys(e.target.value ? [e.target.value] : []),"pressEnter":() => _vm.handleSearch(selectedKeys, confirm, column.dataIndex)}}),_c('a-button',{staticStyle:{"width":"90px","margin-right":"8px"},attrs:{"type":"primary","icon":"search","size":"small"},on:{"click":() => _vm.handleSearch(selectedKeys, confirm, column.dataIndex)}},[_vm._v(" Search ")]),_c('a-button',{staticStyle:{"width":"90px"},attrs:{"size":"small"},on:{"click":() => _vm.handleReset(clearFilters)}},[_vm._v(" Reset ")])],1)}},{key:"filterIcon",fn:function(filtered){return _c('a-icon',{style:({ color: filtered ? '#108ee9' : undefined }),attrs:{"type":"search"}})}},{key:"customRender",fn:function(text, record, index, column){return [(_vm.searchText && _vm.searchedColumn === column.dataIndex)?_c('span',[_vm._l((text
                    .toString()
                    .split(
                      new RegExp(`(?<=${_vm.searchText})|(?=${_vm.searchText})`, 'i')
                    )),function(fragment,i){return [(fragment.toLowerCase() === _vm.searchText.toLowerCase())?_c('mark',{key:i,staticClass:"highlight"},[_vm._v(_vm._s(fragment))]):[_vm._v(_vm._s(fragment))]]})],2):[_c('span',[_vm._v(" "+_vm._s(text))])]]}},{key:"keterangan",fn:function(text){return _c('span',{domProps:{"innerHTML":_vm._s(_vm.getHtml(text))}})}},{key:"keterangan2",fn:function(text){return _c('span',{domProps:{"innerHTML":_vm._s(_vm.getHtml(text))}})}},{key:"jumlah",fn:function(text){return _c('span',{},[_vm._v(" "+_vm._s(_vm.formatNumber(text))+" ")])}},{key:"jumlah2",fn:function(text){return _c('span',{},[_vm._v(" "+_vm._s(_vm.formatNumber(text))+" ")])}},{key:"selisih",fn:function(text){return _c('span',{},[_vm._v(" "+_vm._s(_vm.formatNumber(text))+" ")])}},{key:"status",fn:function(text){return _c('a-tag',{attrs:{"color":text === 'Cocok' ? 'green' : 'red'}},[_vm._v(" "+_vm._s(text)+" ")])}}])})],1),_c('div',{staticClass:"col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-5"},[_c('h5',[_vm._v("Cek Laba Rugi")]),_c('a-table',{staticStyle:{"margin-left":"-13px","margin-right":"-13px"},attrs:{"bordered":false,"columns":_vm.columns,"data-source":_vm.rowDataLabaRugi,"size":"small","loading":_vm.loading,"pagination":{
              hideOnSinglePage: true,
              defaultPageSize: 100,
              showQuickJumper: true,
              showSizeChanger: true,
              showTotal: (total) => `Total ${total} items`,
              pageSizeOptions: ['100', '200', '300'],
            }},scopedSlots:_vm._u([{key:"filterDropdown",fn:function({
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
                column,
              }){return _c('div',{staticStyle:{"padding":"8px"}},[_c('a-input',{directives:[{name:"ant-ref",rawName:"v-ant-ref",value:((c) => (_vm.searchInput = c)),expression:"(c) => (searchInput = c)"}],staticStyle:{"width":"188px","margin-bottom":"8px","display":"block"},attrs:{"placeholder":`Search ${column.dataIndex}`,"value":selectedKeys[0]},on:{"change":(e) =>
                    setSelectedKeys(e.target.value ? [e.target.value] : []),"pressEnter":() => _vm.handleSearch(selectedKeys, confirm, column.dataIndex)}}),_c('a-button',{staticStyle:{"width":"90px","margin-right":"8px"},attrs:{"type":"primary","icon":"search","size":"small"},on:{"click":() => _vm.handleSearch(selectedKeys, confirm, column.dataIndex)}},[_vm._v(" Search ")]),_c('a-button',{staticStyle:{"width":"90px"},attrs:{"size":"small"},on:{"click":() => _vm.handleReset(clearFilters)}},[_vm._v(" Reset ")])],1)}},{key:"filterIcon",fn:function(filtered){return _c('a-icon',{style:({ color: filtered ? '#108ee9' : undefined }),attrs:{"type":"search"}})}},{key:"customRender",fn:function(text, record, index, column){return [(_vm.searchText && _vm.searchedColumn === column.dataIndex)?_c('span',[_vm._l((text
                    .toString()
                    .split(
                      new RegExp(`(?<=${_vm.searchText})|(?=${_vm.searchText})`, 'i')
                    )),function(fragment,i){return [(fragment.toLowerCase() === _vm.searchText.toLowerCase())?_c('mark',{key:i,staticClass:"highlight"},[_vm._v(_vm._s(fragment))]):[_vm._v(_vm._s(fragment))]]})],2):[_c('span',[_vm._v(" "+_vm._s(text))])]]}},{key:"keterangan",fn:function(text){return _c('span',{domProps:{"innerHTML":_vm._s(_vm.getHtml(text))}})}},{key:"keterangan2",fn:function(text){return _c('span',{domProps:{"innerHTML":_vm._s(_vm.getHtml(text))}})}},{key:"jumlah",fn:function(text){return _c('span',{},[_vm._v(" "+_vm._s(_vm.formatNumber(text))+" ")])}},{key:"jumlah2",fn:function(text){return _c('span',{},[_vm._v(" "+_vm._s(_vm.formatNumber(text))+" ")])}},{key:"selisih",fn:function(text){return _c('span',{},[_vm._v(" "+_vm._s(_vm.formatNumber(text))+" ")])}},{key:"status",fn:function(text){return _c('a-tag',{attrs:{"color":text === 'Cocok' ? 'green' : 'red'}},[_vm._v(" "+_vm._s(text)+" ")])}}])})],1)])],2)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }